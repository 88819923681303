import hat from "@images/common/hat.svg";
import bag from "@images/careers/bag.svg";
import relocation from "@images/careers/relocation.svg";
import tax from "@images/common/tax.svg";
import cocktail from "@images/careers/cocktail.svg";
import firstAid from "@images/careers/first-aid.svg";

export const BENEFIT_LIST_ITEMS = [
  {
    title: "Professional education and training budget",
    icon: hat,
  },
  { 
    title: "24 working days vacation", 
    icon: bag 
  },
  {
    title: "WOW team-building events",
    icon: cocktail,
  },
  {
    title: "Paid sick leave",
    icon: firstAid,
  },
  {
    title: "Relocation assistance",
    icon: relocation,
  },
];
